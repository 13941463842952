h1 {
    position: absolute;
    left: -100%;
}

.about-wrapper {
    background-color: white;
    padding: 1rem 1rem;
}

.about{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(35, 91, 110);
    max-width: 1200px;
}

.about__photo {
    border-radius: 5%;
    width: 60%;
}

.about__name {
    font-weight: bold;
    font-size: 1.7rem;
    padding: 1em 1em .5em;
    text-align: center;
}

.about__description {
    text-align: justify;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
}

.about__line {
    margin: 1em;
}

@media(min-width: 790px) {
    .about{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 2em 2em;
        align-items: center;
        gap: 10%;
    }
    
    .about__photo {
        border-radius: 5%;
        width: 35%;
    }

    .description-wrapper {
        width: 50%;
    }

    .about__name {
        font-weight: bold;
        font-size: 1.7rem;
        padding: 1em 1em .5em;
        text-align: center;
    }

    .about__description {
        line-height: 2rem;
    }
}