.hero-wrapper {
    margin-top: 80px;
    box-shadow: rgba(50, 50, 93, 0.25) 0 0 100px -20px, rgba(0, 0, 0, 0.3) 0 30px 10px -30px, rgba(10, 37, 64, 0.35) 0 -2px 6px 0 inset;
    background-color: black;
}

.hero__button{
    font-size: 1rem;
    font-weight: 600;
    position: relative;
    border-radius: 0.5rem;
    border: 1px solid white;
    background-color: rgba(0, 0, 0, 0.25);
    color: inherit;
    text-align: center;
    transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s; /* Safari */
    box-shadow: rgba(0, 0, 0, 0.17) 0 -23px 25px 0 inset, rgba(0, 0, 0, 0.15) 0 -36px 30px 0 inset, rgba(0, 0, 0, 0.1) 0 -79px 40px 0 inset, rgba(0, 0, 0, 0.06) 0 2px 1px, rgba(0, 0, 0, 0.09) 0 4px 2px, rgba(0, 0, 0, 0.09) 0 8px 4px, rgba(0, 0, 0, 0.09) 0 16px 8px, rgba(0, 0, 0, 0.09) 0 32px 16px;
}

.hero__button:after {
    border-radius: 0.5rem;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 10px 40px white;
}

.hero__button:active:after {
    box-shadow: 0 0 0 0 white;
    position: absolute;
    left: 0;
    top:0;
    opacity: 1;
    transition: 0s;
}

.hero__button:active {
    top: 1px;
}

@media(min-width: 790px) {
    .hero-wrapper {
        background-position: bottom;
    }

    .hero {
        height: 580px ;
    }
}