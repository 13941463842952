.footer-wrapper {
    padding: 0 .5rem;
    background-color: #202122;
}

.footer {
    color: rgb(131, 128, 128);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: .1rem;
    font-size: .7rem;
}

.footer__item {
    display: flex;
    gap: .5rem;
}

hr {
    width: 90%;
    margin: .2em;
}

.social {
    margin-top: 10px;
    font-size: 1.5rem;
}

a {
    color: inherit;
}

.footer__details {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: .7rem;
    line-height: 1rem;
}

.footer__copy-rights {
    margin: .5rem;
}

.footer__signature {
    color: rgb(61, 159, 160);
    font-weight: bold;
}

@media(min-width: 790px) {
    .footer {
        color: rgb(131, 128, 128);
        padding: 0 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: .1rem;
        font-size: .7rem;
    }
    
    .footer__item {
        display: flex;
        gap: .5rem;
    }
    
    hr {
        width: 90%;
        margin: .2em;
    }
}