* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    --background-color-dark: linear-gradient(to right top,#235b6e,#3d9fa0);
    --background-color-light: #ffffff;
    --menu-text-color: #3d9fa0;
    --text-color-dark: #235b6e;
    --text-color-light: #ffffff;
}

.header, .home-page, .hero, .treatment, .about, .embla, .location, .contact, .footer {
	margin: 0 auto;
	max-width: 1200px;
}

body {
    color: var(--background-color-light);
    font-family: 'Open Sans', sans-serif;
    margin: 0 auto;
}

ul {
    list-style: none;
}