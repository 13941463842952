.location-wrapper {
    padding: 1rem 1rem;
}

.location {
    color: var(--text-color-dark);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.location__title {
    font-size: 2rem;
    font-weight: 800;
    text-align: center;
    margin-bottom: 1rem;
}

.location__address {
    text-align: center;
    font-weight: 800;
    font-size: 1.2rem;
}

.location__opening-hours {
    font-weight: 800;
    
}

.opening-hours__title {
    text-decoration: underline;
    font-size: 1.7rem;
}

.opening-hours__title, .opening-hours__description {
    text-align: center;
    font-weight: 600;
}

.map-container {
    width: 100%;
    height: 300px;
    border: 2px solid var(--text-color-dark);
    border-radius: 10px;
}
